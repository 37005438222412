<template>
	<screen class="donor-list-view" size="large">
		<h1 slot="title">New Donors</h1>
		<template slot="buttons">
			<Roundup-Button color="white" @click="handleDownload"
				>Export CSV</Roundup-Button
			>
		</template>
		<div slot="chart" class="mt-4">
			<div class="chart-options">
				<el-date-picker
					v-model="date"
					type="daterange"
					start-placeholder="Start date"
					end-placeholder="End date"
					size="small"
					@change="dateChange"
				>
				</el-date-picker>
			</div>
			<chart :chartData="graph" prepend=""></chart>
		</div>
		<el-table
			slot="table"
			:data="items"
			@row-click="navigateToDonorDetail"
			style="width:100%"
		>
			<el-table-column prop="user.first_name" label="First Name">
			</el-table-column>

			<el-table-column prop="user.last_name" label="Last Name">
			</el-table-column>
			<el-table-column prop="user.phone" label="Phone"> </el-table-column>
			<el-table-column prop="user.email" label="Email"> </el-table-column>
			<el-table-column prop="created_at" label="SignUp Date">
			</el-table-column>
			<el-table-column prop="donor_type" label="Donor Type">
			</el-table-column>
			<el-table-column prop="monthly_gift" label="Monthly Minimum">
			</el-table-column>
			<el-table-column prop="has_valid_card" label="Has Card">
			</el-table-column>
			<el-table-column prop="is_paused" label="Donations Paused">
			</el-table-column>
			<el-table-column prop="active_donor" label="Active Donor">
			</el-table-column>
		</el-table>
		<el-pagination
			v-if="items.length"
			@current-change="pageChange"
			:current-page="page"
			:page-size="perPage"
			layout="prev, pager, next, total"
			:total="total"
		>
		</el-pagination>
	</screen>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { get } from '@/utilities'
import table from '@/mixins/table'
import download from '@/mixins/download'
import moment from 'moment'

import UiButton from '../../ui/Button'
import Screen from '../../ui/Screen'
import Chart from '../../ui/Chart'

const columns = [
	{ label: 'First Name', value: 'user.first_name' },
	{ label: 'Last Name', value: 'user.last_name' },
	{ label: 'Email', value: 'user.email' },
	{ label: 'Phone', value: 'user.phone' },
	{ label: 'SignUp Date', value: 'created_at' },
	{ label: 'Has Card', value: 'has_valid_card' },
	{ label: 'Donor Type', value: 'donor_type' },
	{ label: 'Monthly Min. Gift', value: 'monthly_gift' },
	{ label: 'Donations Paused', value: 'is_paused' },
	{ label: 'Active Donor', value: 'active_donor' }
]

export default {
	name: 'new-donors-report-view',
	components: { UiButton, Screen, Chart },
	mixins: [table, download],
	computed: {
		...mapState({
			items: ({ reportList }) => reportList.items,
			total: ({ reportList }) => reportList.total,
			graph: ({ reportList }) => reportList.graph,
			authToken: ({ me }) => me.token
		})
	},
	data() {
		return {
			date: null,
			tableParamKeys: ['startDate', 'endDate'],
			columns
		}
	},
	head: {
		title: {
			inner: 'Reports - New Donors'
		}
	},
	methods: {
		...mapActions(['getNewDonorsReport']),
		navigateToDonorDetail(donor) {
			let donorId = donor.id
			if (localStorage.demo && localStorage.demo !== 'false') {
				donorId = -1
			}

			this.$router.push({
				name: 'donors.detail',
				params: { donorId: donorId },
				query: {
					first_name: donor.first_name,
					last_name: donor.last_name,
					created_at: new Date(donor.created_at).getTime()
				}
			})
		},
		paramsChanged(params) {
			this.getNewDonorsReport(params)
		},
		handleDownload() {
			window.analytics.track('download_new_donor_report')
			var params = this.getParameters()
			params.perPage = 1000
			params.page = 0
			this.postParameters(
				'/reports/new-donors/csv',
				params,
				this.authToken
			)
		},
		dateChange(dates) {
			let params = this.getParameters()
			if (dates) {
				params.startDate = moment(dates[0]).format('YYYY-MM-DD')
				params.endDate = moment(dates[1]).format('YYYY-MM-DD')
			}
			this.setParameters(params)
		},
		get
	},
	mounted() {
		this.getNewDonorsReport(this.getParameters())
	}
}
</script>

<style lang="scss">
@import url('//unpkg.com/element-ui@2.13.2/lib/theme-chalk/index.css');

@import '../../../styles/variables';
</style>
